
import { cva } from 'class-variance-authority'
import { LABELS } from '~/types/productLabels'
export default {
  name: 'DSLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
    theme: {
      validator: (value) =>
        [
          LABELS.interactive,
          LABELS.white,
          LABELS.blue,
          LABELS.lightBlue,
        ].includes(value),
      default: LABELS.interactive,
    },
  },
  computed: {
    styles() {
      const body = cva('px-2 py-1 rounded-lg select-none text-sm', {
        variants: {
          theme: {
            [LABELS.interactive]:
              'duration-200 bg-white hover:border-white text-grey-600 border border-grey-600',
            [LABELS.white]: 'bg-white text-blue-800',
            [LABELS.blue]: 'bg-blue-800 text-white',
            [LABELS.lightBlue]: 'bg-blue-200 text-blue-800',
          },
        },
      })
      return body({ theme: this.theme })
    },
  },
}
