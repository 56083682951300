
import { formPropsMixin } from '~/mixins'
export default {
  mixins: [formPropsMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    style() {
      const sizes = {
        big: {
          errorText: 'text-base',
          labelText: 'text-base',
          checkboxSize: 'w-5 h-5',
        },
        default: {
          errorText: 'text-sm',
          labelText: 'text-sm',
          checkboxSize: 'w-4 h-4',
        },
      }
      return sizes[this.size]
    },
  },
  mounted() {
    this.$emit('field-ready')
  },
}
