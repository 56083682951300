
import { LABELS } from '~/types/productLabels'
import { productsCompareMixin } from '~/mixins'
export default {
  name: 'ProductTelematics',
  mixins: [productsCompareMixin],
  props: {
    matchPhrase: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    compare: {
      type: Boolean,
      default: false,
    },
    media: {
      type: [Object, String, Array],
      default: '/images/default-image.jpg',
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
    extras: {
      type: Object,
      default: () => ({ features: [] }),
    },
    connectionTypes: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
      default: '',
    },
    sortTag: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    labelTheme() {
      return this.sortTag?.code === 'new'
        ? LABELS.blue
        : this.sortTag?.code === 'bestseller'
          ? LABELS.white
          : LABELS.lightBlue
    },
    cardsTopAlignment() {
      let alignment = 'justify-start'
      if (this.extras.model && this.sortTag.code) alignment = 'justify-between'
      if (!this.extras.model && this.sortTag.code) alignment = 'justify-end'
      return alignment
    },
    showConnectionTypesOnHover() {
      return (
        Array.isArray(this.connectionTypes) &&
        this.connectionTypes.length &&
        this.connectionTypes.some((item) => item.title.length)
      )
    },
    matchedTitle() {
      if (!this.matchPhrase) return this.title

      return this.title
        .toLowerCase()
        .replace(
          this.matchPhrase.toLowerCase(),
          `<span class="text-blue-800">${this.matchPhrase.toLowerCase()}</span>`,
        )
    },
  },
}
